<template>
  <div>
    <div style="width: 100%; height: 70vh">
      <van-nav-bar
        title="我的"
        @click-left="onClickLeft"
        @click-right="onClickRight"
      >
        <template #right>
          <van-icon name="setting" size="18" />
        </template>
      </van-nav-bar>
      <div
        style="
          height: 16vh;
          padding-bottom: 30px;
          background: linear-gradient(
            to right,
            rgb(223, 236, 156),
            rgb(130, 198, 159)
          );
        "
      >
        <div
          style="
            width: 100px;
            margin: 0 auto;
            border-radius: 50%;
            padding-top: 40px;
          "
        >
          <img
            :src="imgUrl"
            style="
              width: 50px;
              height: 50px;
              margin-left: 25px;
              border-radius: 50%;
            "
          />
          <div
            style="
              text-align: center;
              font-size: 16px;
              padding-top: 10px;
              color: white;
            "
          >
            {{ userName }}
          </div>
        </div>
      </div>
      <div>
        <van-cell icon="star" title="我的收藏" is-link @click="toNext1(1)" />
        <van-cell icon="clock" title="历史足迹" is-link @click="toNext1(2)" />
        <!-- <van-cell
          icon="todo-list"
          title="我的发布"
          is-link
          @click="toNext1(7)"
        /> -->
        <!-- <van-cell
          icon="comment-circle"
          title="消息中心"
          is-link
          @click="toNext1(3)"
        />
        <van-cell
          icon="phone-circle"
          title="联系我们"
          is-link
          @click="toNext1(4)"
        />
        <van-cell
          icon="description"
          title="法律声明"
          is-link
          @click="toNext1(5)"
        /> -->
        <!-- <van-cell icon="info" title="退出登录" is-link @click="toNext1(6)" /> -->
      </div>
    </div>
    <home-view :active="4"></home-view>
  </div>
</template>
  <script setup lang="ts">
import HomeView from "./HomeView.vue";
import { showConfirmDialog } from "vant";
import { useRouter } from "vue-router";
const router = useRouter();
import { delUser,  delWxUser } from "@/api/user";
import { showToast } from "vant";
import { onMounted, onUnmounted, ref } from "vue";
import { showDialog } from "vant";
let userName = ref("admin");
const onClickLeft = () => history.back();
const onClickRight = () => {
  router.push({ name: "Setting", params: { index: 0 } });
};
const imgUrl = ref("");
onMounted(() => {
  userName.value = localStorage.getItem("username");
  imgUrl.value = localStorage.getItem("userImage");
});
const toNext1 = (val) => {
  // console.log(val);
  if (val === 6) {
    showConfirmDialog({
      title: "提示",
      message: "确认退出登录吗",
    })
      .then(() => {
        localStorage.removeItem("token");
        delWxUser().then((res) => {
          localStorage.removeItem("token");
          router.replace("/");
        });
      })
      .catch(() => {
        // on cancel
      });
  } else if (val === 4) {
    showDialog({
      title: "联系方式",
      message: "电话：xxxxxxxxxx\n邮箱：xxxx@xx.xx",
    }).then(() => {
      // on close
    });
  } else {
    router.push({ name: "Setting", params: { index: val } });
  }
};
</script>

<style scoped>
.van-cell {
  padding: 15px !important;
}
::v-deep .van-hairline--bottom:after {
  border: none !important;
  box-shadow: none;
}
::v-deep .van-cell {
  color: rgb(96, 96, 96);
}
</style>