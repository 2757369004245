<!-- 我的发布 -->
<template>
  <div>
    <div class="scanning" v-if="sacShow">
      <scan-code-page @sacExit="sacExit" :ids="ids"></scan-code-page>
    </div>
    <van-nav-bar title="个人中心" @click-right="onClickRight">
      <template #right>
        <van-icon name="chat" size="18" />
      </template>
    </van-nav-bar>
    <div style="width: 100%; height: 10px"></div>
    <div style="width: 100%">
      <van-tabs
        v-model:active="active"
        type="card"
        active-color="#FF0000"
        title-inactive-color="rgb(141,141,141)"
      >
        <div style="width: 100%; height: 5px"></div>
        <van-tab title="案例">
          <van-pull-refresh
            v-model="loading"
            @refresh="onRefresh"
            :disabled="freshBool"
          >
            <div
              class="msgbox"
              @scroll="handleScroll"
              v-if="caseData.length > 0"
            >
              <van-card
                :title="item.title"
                :thumb="
                  item.type == 2
                    ? item.videoTitle
                    : item.type == 1
                    ? item.filesList[0].url
                    : require('@/assets/文件.png')
                "
                @click="nextToMsg(item.caseId, 0)"
                v-for="(item, index) in caseData"
                :key="index"
                style="height: 128px"
              >
                <template #thumb>
                  <img
                    :src="
                      item.type == 2
                        ? item.videoTitle
                        : item.type == 1
                        ? item.filesList[0].url
                        : require('@/assets/文件.png')
                    "
                    style="width: 40vw; height: 110px"
                  />
                </template>
                <template #tags>
                  <!-- 最多显示三行 -->
                  <!-- <div
                    class="van-multi-ellipsis--l3"
                    style="margin-bottom: 6px"
                  >
                    {{ item.content }}
                  </div> -->
                  <div style="padding-top: 6px">
                    <span
                      v-for="(item1, index1) in Department"
                      :key="index1"
                      style="
                        margin-right: 5px;
                        font-size: 12px;
                        color: rgb(152, 152, 152);
                      "
                      v-show="item.departmentType == JSON.stringify(index1)"
                      >{{ item1 }}</span
                    >
                    <span
                      style="
                        margin-right: 5px;
                        font-size: 12px;
                        color: rgb(143, 195, 32);
                      "
                      v-if="item.type == 1"
                      >图文</span
                    >
                    <span
                      style="
                        margin-right: 5px;
                        font-size: 12px;
                        color: rgb(143, 195, 32);
                      "
                      v-if="item.type == 2"
                      >视频</span
                    >
                    <span
                      style="
                        margin-right: 5px;
                        font-size: 12px;
                        color: rgb(143, 195, 32);
                      "
                      v-if="item.type == 3"
                      >病例文本</span
                    >
                  </div>
                  <div class="msgtext">
                    <span>作者：{{ item.createBy }}</span>
                    <span style="padding-left: 10px"
                      >浏览量：{{ item.viewCount }}</span
                    >
                  </div>
                  <div style="padding-top: 6px">
                    <van-button
                      @click="editBtn(item.caseId, 1)"
                      icon="edit"
                      style="padding: 0 10px"
                      round
                      color="linear-gradient(
    to right,
    rgb(130, 200, 160),
    rgb(130, 200, 160)
  )"
                      size="mini"
                      >编辑</van-button
                    >
                    <van-button
                      @click="delBtn(item.caseId, 1)"
                      icon="delete-o"
                      style="padding: 0 10px"
                      round
                      color="linear-gradient(
    to right,
    rgb(255,85,85),
    rgb(255,85,85)
  )"
                      size="mini"
                      >删除</van-button
                    >
                  </div>
                </template>
              </van-card>
            </div>
            <div style="padding-top: 100px" v-else>
              <van-empty
                image="https://fastly.jsdelivr.net/npm/@vant/assets/custom-empty-image.png"
                image-size="80"
                description="暂无内容"
              />
            </div>
            <div style="width: 100%; height: 55px"></div>
          </van-pull-refresh>
        </van-tab>
        <van-tab title="贴吧">
          <van-pull-refresh
            v-model="loading"
            @refresh="onRefresh"
            :disabled="freshBool"
          >
            <div
              class="msgbox"
              @scroll="handleScroll"
              v-if="forumData.length > 0"
            >
              <van-card
                :title="item.title"
                :thumb="
                  item.type == 2
                    ? item.videoTitle
                    : item.type == 1
                    ? item.filesList[0].url
                    : require('@/assets/文件.png')
                "
                @click="nextToMsg(item.postBarId, 1)"
                v-for="(item, index) in forumData"
                :key="index"
                style="height: 128px"
              >
                <template #thumb>
                  <img
                    :src="
                      item.type == 2
                        ? item.videoTitle
                        : item.type == 1
                        ? item.filesList[0].url
                        : require('@/assets/文件.png')
                    "
                    style="width: 40vw; height: 110px"
                  />
                </template>
                <template #tags>
                  <div style="padding-top: 6px">
                    <span
                      v-for="(item1, index1) in Department"
                      :key="index1"
                      style="
                        margin-right: 5px;
                        font-size: 12px;
                        color: rgb(152, 152, 152);
                      "
                      v-show="item.departmentType == JSON.stringify(index1)"
                      >{{ item1 }}</span
                    >
                    <span
                      style="
                        margin-right: 5px;
                        font-size: 12px;
                        color: rgb(143, 195, 32);
                      "
                      v-if="item.type == 1"
                      >图文</span
                    >
                    <span
                      style="
                        margin-right: 5px;
                        font-size: 12px;
                        color: rgb(143, 195, 32);
                      "
                      v-if="item.type == 2"
                      >视频</span
                    >
                    <span
                      style="
                        margin-right: 5px;
                        font-size: 12px;
                        color: rgb(143, 195, 32);
                      "
                      v-if="item.type == 3"
                      >病例文本</span
                    >
                  </div>
                  <div class="msgtext">
                    <span>作者：{{ item.createBy }}</span>
                    <span style="padding-left: 10px"
                      >浏览量：{{ item.viewCount }}</span
                    >
                  </div>
                  <div style="padding-top: 6px">
                    <van-button
                      icon="edit"
                      style="padding: 0 10px"
                      round
                      color="linear-gradient(
    to right,
    rgb(130, 200, 160),
    rgb(130, 200, 160)
  )"
                      size="mini"
                      @click="editBtn(item.postBarId, 1)"
                      >编辑</van-button
                    >
                    <van-button
                      icon="delete-o"
                      @click="delBtn(item.postBarId, 1)"
                      style="padding: 0 10px"
                      round
                      color="linear-gradient(
    to right,
    rgb(255,85,85),
    rgb(255,85,85)
  )"
                      size="mini"
                      >删除</van-button
                    >
                  </div>
                </template>
              </van-card>
            </div>
            <div style="padding-top: 100px" v-else>
              <van-empty
                image="https://fastly.jsdelivr.net/npm/@vant/assets/custom-empty-image.png"
                image-size="80"
                description="暂无内容"
              />
            </div>
            <div style="width: 100%; height: 55px"></div>
          </van-pull-refresh>
        </van-tab>
        <van-tab title="文献">
          <van-pull-refresh
            v-model="loading"
            @refresh="onRefresh"
            :disabled="freshBool"
          >
            <div
              class="msgbox"
              @scroll="handleScroll"
              v-if="wenData.length > 0"
            >
              <van-card
                :title="item.title"
                :thumb="item.literatureCoverPictureUrl"
                @click="nextToMsg(item.literatureId, 2)"
                v-for="(item, index) in wenData"
                :key="index"
                style="height: 128px"
              >
                <template #thumb>
                  <img
                    :src="
                      item.type == 2
                        ? item.videoTitle
                        : item.type == 1
                        ? item.literatureCoverPictureUrl
                        : item.literatureCoverPictureUrl
                    "
                    style="width: 40vw; height: 110px"
                  />
                </template>
                <template #tags>
                  <!-- <div
                    class="van-multi-ellipsis--l3"
                    style="margin-bottom: 6px"
                  >
                    {{ item.content }}
                  </div> -->
                  <div style="padding-top: 6px">
                    <span
                      v-for="(item1, index1) in Department"
                      :key="index1"
                      style="
                        margin-right: 5px;
                        font-size: 12px;
                        color: rgb(152, 152, 152);
                      "
                      v-show="item.departmentType == JSON.stringify(index1)"
                      >{{ item1 }}</span
                    >
                    <span
                      style="
                        margin-right: 5px;
                        font-size: 12px;
                        color: rgb(143, 195, 32);
                      "
                      v-if="item.type == 1"
                      >图文</span
                    >
                    <span
                      style="
                        margin-right: 5px;
                        font-size: 12px;
                        color: rgb(143, 195, 32);
                      "
                      v-if="item.type == 2"
                      >视频</span
                    >
                    <span
                      style="
                        margin-right: 5px;
                        font-size: 12px;
                        color: rgb(143, 195, 32);
                      "
                      v-if="item.type == 3"
                      >病例文本</span
                    >
                  </div>
                  <div class="msgtext">
                    <span>作者：{{ item.createBy }}</span>
                    <span style="padding-left: 10px"
                      >浏览量：{{ item.viewCount }}</span
                    >
                  </div>
                  <div style="padding-top: 6px">
                    <van-button
                      icon="edit"
                      style="padding: 0 10px"
                      round
                      color="linear-gradient(
    to right,
    rgb(130, 200, 160),
    rgb(130, 200, 160)
  )"
                      size="mini"
                      @click="editBtn(item.postBarId, 1)"
                      >编辑</van-button
                    >
                    <van-button
                      icon="delete-o"
                      @click="delBtn(item.postBarId, 1)"
                      style="padding: 0 10px"
                      round
                      color="linear-gradient(
    to right,
    rgb(255,85,85),
    rgb(255,85,85)
  )"
                      size="mini"
                      >删除</van-button
                    >
                  </div>
                </template>
              </van-card>
            </div>
            <div style="padding-top: 100px" v-else>
              <van-empty
                image="https://fastly.jsdelivr.net/npm/@vant/assets/custom-empty-image.png"
                image-size="80"
                description="暂无内容"
              />
            </div>
            <div style="width: 100%; height: 55px"></div> </van-pull-refresh
        ></van-tab>
        <van-tab title="直播">
          <van-pull-refresh
            v-model="loading"
            @refresh="onRefresh"
            :disabled="freshBool"
          >
            <div class="msgbox" @scroll="handleScroll" v-if="videoData.length">
              <van-card
                :title="item.roomName"
                :thumb="item.coverUrl"
                @click="nextToMsgVideo(item)"
                v-for="(item, index) in videoData"
                :key="index"
              >
                <template #thumb>
                  <img
                    :src="item.coverUrl"
                    style="width: 38vw; height: 118px"
                  />
                </template>
                <template #tags>
                  <div style="padding-top: 6px">
                    <span
                      v-for="(item1, index1) in Department"
                      :key="index1"
                      style="
                        margin-right: 5px;
                        font-size: 12px;
                        color: rgb(152, 152, 152);
                      "
                      v-show="item.type == JSON.stringify(index1)"
                      >{{ item1 }}</span
                    >
                    <span
                      v-show="item.status == '1'"
                      style="
                        margin-right: 5px;
                        font-size: 12px;
                        color: rgb(143, 195, 32);
                      "
                      >直播中</span
                    >
                    <span
                      v-show="item.status == '0'"
                      style="
                        margin-right: 5px;
                        font-size: 12px;
                        color: rgb(143, 195, 32);
                      "
                      >未开始</span
                    >
                    <span
                      v-show="item.status == '2'"
                      style="
                        margin-right: 5px;
                        font-size: 12px;
                        color: rgb(143, 195, 32);
                      "
                      >已结束</span
                    >
                  </div>
                  <div class="timetext">
                    <!-- <div style="padding-bottom: 6px">
                      观看人数：{{ item.viewCount }}
                    </div> -->
                    <div style="padding-bottom: 6px">
                      <van-icon name="underway-o" /> {{ item.startTime }}
                    </div>
                    <div style="padding-bottom: 6px">
                      <van-icon name="underway" /> {{ item.endTime }}
                    </div>
                    <div @click.stop>
                      <van-button
                        v-if="item.status == 0 || item.status == 1"
                        icon="records-o"
                        style="padding: 0 10px"
                        round
                        color="rgb(49,193,231)"
                        size="mini"
                        @click="EditVideo(item)"
                        >编辑信息</van-button
                      >
                      <van-button
                        v-if="item.status == 0"
                        icon="play-circle-o"
                        style="padding: 0 10px"
                        round
                        color="rgb(130, 200, 160)"
                        size="mini"
                        @click="sacTo(item)"
                        >开始直播</van-button
                      >
                      <!-- @click="OpenVideo(item)" -->
                      <van-button
                        v-if="item.status == 1"
                        icon="stop-circle-o"
                        style="padding: 0 10px"
                        round
                        color="rgb(255,85,85)"
                        size="mini"
                        @click="CloseVideo(item)"
                        >结束直播</van-button
                      >
                      <!-- <van-button
                        v-if="item.status == 2"
                        icon="delete-o"
                        style="padding: 0 10px"
                        round
                        color="rgb(255,85,85)"
                        size="mini"
                        >删除直播</van-button
                      > -->
                      <van-button
                        v-if="item.status == 3"
                        icon="warning-o"
                        style="padding: 0 10px"
                        round
                        color="rgb(95,99,104)"
                        size="mini"
                        >已过期</van-button
                      >
                    </div>
                  </div>
                </template>
              </van-card>
            </div>
            <div style="padding-top: 100px" v-else>
              <van-empty
                image="https://fastly.jsdelivr.net/npm/@vant/assets/custom-empty-image.png"
                image-size="80"
                description="暂无内容"
              />
            </div>
            <div style="width: 100%; height: 55px"></div>
          </van-pull-refresh>
        </van-tab>
      </van-tabs>
    </div>
    <home-view :active="3"></home-view>
  </div>
</template>
<script setup lang="ts">
import { onMounted, onUnmounted, ref, watch } from "vue";
import { useRouter } from "vue-router";
import { showConfirmDialog } from "vant";
import HomeView from "./HomeView.vue";
import {
  forumList,
  caseList,
  collLikeApi,
  cancelCollLikeApi,
  delforumList,
  delcaseList,
  getwenList,
} from "@/api/forum";
import { videoRoomList, startLive, endLive } from "@/api/video";
import { showDialog } from "vant";
const router = useRouter();
import { showToast } from "vant";
import ScanCodePage from "@/components/ScanCodePage.vue";

import useClipboard from "vue-clipboard3";
const { toClipboard } = useClipboard();
const active = ref(0);
const freshBool = ref(false);
const caseData = ref([]);
const forumData = ref([]);
const wenData = ref([]);

const loading = ref(false);
const detailsBool = ref(true);
const favorBtncolor = ref("");
const likeBtncolor = ref("");
const username = ref("");
const videoData = ref([]);
const sacShow = ref(false);
const ids = ref("");
const Department = ref([
  "胸外科",
  "肝胆外科",
  "神经外科",
  "妇科",
  "泌尿外科",
  "骨科",
  "小儿外科",
]);
watch(
  active,
  (newValue, oldValue) => {
    console.log(newValue);

    if (newValue == 0) {
      getcaseList();
    }
    if (newValue == 1) {
      getforumList();
    }
    if (newValue == 2) {
      getwenLists();
    }
    if (newValue == 3) {
      getvideoList();
    }
    localStorage.setItem("active", newValue);
    console.log(newValue);
  }
  // { immediate: true, deep: true }
);
onMounted(() => {
  let avtBool = localStorage.getItem("active");
  if (avtBool == null || avtBool == 0) {
    active.value = 0;
  } else if (avtBool == 1) {
    active.value = 1;
  } else if (avtBool == 2) {
    active.value = 2;
  } else {
    active.value = 3;
  }
  username.value = localStorage.getItem("username");
  getcaseList();
});

//案例列表
const getcaseList = () => {
  let data = {
    pageNum: 1,
    pageSize: 100,
    createBy: username.value,
  };
  caseList(data).then((res) => {
    if (res.status == 200) {
      caseData.value = res.data.rows;
    }
  });
};
//贴吧列表
const getforumList = () => {
  let data = {
    pageNum: 1,
    pageSize: 100,
    createBy: username.value,
  };
  forumList(data).then((res) => {
    console.log("f", res);
    if (res.status == 200) {
      forumData.value = res.data.rows;
    }
  });
};
//文献列表
const getwenLists = () => {
  let data = {
    pageNum: 1,
    pageSize: 100,
    createBy: username.value,
  };
  getwenList(data).then((res) => {
    console.log("f", res);
    if (res.status == 200) {
      wenData.value = res.data.rows;
    }
  });
};
//直播列表
const getvideoList = () => {
  // loadBool.value = true;
  let dataValue = {
    createBy: username.value,
  };
  videoRoomList(dataValue).then((res) => {
    if (res.data.code == 200) {
      videoData.value = res.data.rows;
      // loadBool.value = false;
    }
  });
};

//刷新
const onRefresh = () => {
  if (active.value == 0) {
    let data = {
      pageNum: 1,
      pageSize: 100,
      createBy: username.value,
    };
    //
    caseList(data)
      .then((res) => {
        if (res.status == 200) {
          caseData.value = res.data.rows;
          showToast("刷新成功");
          loading.value = false;
        }
      })
      .catch((err) => {
        showToast("刷新失败");
        loading.value = false;
      });
  }
  if (active.value == 1) {
    let data = {
      pageNum: 1,
      pageSize: 100,
      createBy: username.value,
    };
    forumList(data)
      .then((res) => {
        if (res.status == 200) {
          forumData.value = res.data.rows;
          showToast("刷新成功");
          loading.value = false;
        }
      })
      .catch((err) => {
        showToast("刷新失败");
        loading.value = false;
      });
  }
  if (active.value == 2) {
    let data = {
      pageNum: 1,
      pageSize: 100,
      createBy: username.value,
    };
    getwenList(data)
      .then((res) => {
        if (res.status == 200) {
          wenData.value = res.data.rows;
          showToast("刷新成功");
          loading.value = false;
        }
      })
      .catch((err) => {
        showToast("刷新失败");
        loading.value = false;
      });
  }
  if (active.value == 3) {
    let dataValue = {
      createBy: username.value,
    };
    videoRoomList(dataValue).then((res) => {
      if (res.data.code == 200) {
        videoData.value = res.data.rows;
        showToast("刷新成功");
        loading.value = false;
      }
    });
  }
};

//滚动条触顶
const handleScroll = (event: any) => {
  const scrollTop = event.target.scrollTop;
  if (scrollTop == 0) {
    freshBool.value = false;
  } else {
    freshBool.value = true;
  }
};
//进入详情
const nextToMsg = (value: any, index: any) => {
  router.push({
    path: "/TiebMsg",
    query: {
      value: value,
      index: index,
    },
  });
};

//进入详情
const nextToMsgVideo = (value) => {
  let obj = JSON.stringify(value);
  router.push({
    path: "/VideoMsg",
    query: {
      value: obj,
    },
  });
};

//编辑
const editBtn = (value: any, index: any) => {
  window.event.cancelBubble = true; //取消事件的冒泡机制
  let IN: any = "";
  if (active.value == 0) {
    IN = 2;
  } else {
    IN = 3;
  }
  router.push({
    path: "/ReleaseIndex",
    query: {
      value: IN,
      id: value,
    },
  });
};
const delBtn = (value: any, index: any) => {
  window.event.cancelBubble = true; //取消事件的冒泡机制
  showConfirmDialog({
    title: "提示",
    message: "确认删除该条发布吗",
  })
    .then(() => {
      if (active.value == 0) {
        delcaseList(value).then((res) => {
          if (res.data.code == 200) {
            getcaseList();
            showToast("删除成功");
          }
        });
      } else {
        delforumList(value).then((res) => {
          if (res.data.code == 200) {
            getforumList();
            showToast("删除成功");
          }
        });
      }
    })
    .catch(() => {
      // on cancel
    });
};
const onClickRight = () => {
  router.push({ name: "Setting", params: { index: 3 } });
};

//开播
const OpenVideo = (val: any) => {
  showConfirmDialog({
    title: "请点击复制推流地址",
    message: val.livePushUrl,
    confirmButtonText: "复制地址",
  })
    .then(async () => {
      // on confirm
      // navigator.clipboard.writeText(val.livePushUrl);
      await toClipboard(val.livePushUrl);
      showDialog({
        title: "开始直播",
        message: "请先进行推流再点击确认开播",
        theme: "round-button",
      }).then(() => {
        // on close
        let data = {
          id: val.id,
          status: "1",
        };
        startLive(data).then((res) => {
          console.log(res);
          if (res.data.code == 200) {
            showToast("开播成功");
            sessionStorage.setItem("videoStatus", 0);
            router.push({
              path: "/VideoIndex",
            });
          }
        });
      });
    })
    .catch(() => {
      // on cancel
    });
};
//结束直播
const CloseVideo = (val: any) => {
  showConfirmDialog({
    title: "结束直播",
    message: "是否确定结束直播",
  })
    .then(() => {
      // on confirm
      let data = {
        id: val.id,
        status: "2",
      };
      endLive(data).then((res) => {
        if (res.data.code == 200) {
          let dataValue = {
            createBy: username.value,
          };
          videoRoomList(dataValue).then((res) => {
            if (res.data.code == 200) {
              videoData.value = res.data.rows;
              showToast("结束直播成功");
              loading.value = false;
            }
          });
        }
      });
    })
    .catch(() => {});
};
//编辑
const EditVideo = (val: any) => {
  router.push({
    path: "/ReleaseVideo",
    query: {
      id: JSON.stringify(val),
    },
  });
};
//打开扫描二维码
const sacTo = (value: any) => {
  sacShow.value = true;
};
const sacExit = (value: any) => {
  sacShow.value = value;
};
</script>
  <style scoped>
.msgbox {
  width: 96%;
  height: calc(100vh - 163px);
  overflow-y: scroll;
  margin: 5px auto 0 auto;
  padding: 5px;
}
::v-deep .van-card__content > div {
  padding-left: calc(100% - 45vw);
}
.msgtext {
  padding-top: 6px;
  color: rgb(192, 196, 204);
  transform: scale(0.8);
  transform-origin: top left;
}
</style>
  <style>
.van-button__text {
  padding-top: 2px;
}
.scanning {
  width: 100vw;
  height: 90vh;
  /* background-color: red; */
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
}
</style>