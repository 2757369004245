import request from "@/utils/request";

export function videoRoomList(data: any) {
    return request({
        url: "/room/list",
        method: "get",
        params: data
    })
}

export function addVideoList(data: any) {
    return request({
        url: '/room',
        method: 'post',
        data,
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    })
}
//直播评论
export function getReviewList(data: any) {
    return request({
        url: '/room/review/list',
        method: 'get',
        params: data
    })
}
// 添加直播评论
export function addReviewList(data: any) {
    return request({
        url: '/room/review',
        method: 'post',
        data: data
    })
}
// 开始直播
export function startLive(data: any) {
    return request({
        url: '/room/startLive',
        method: 'put',
        data: data
    })
}
// 结束直播
export function endLive(data: any) {
    return request({
        url: '/room/endLive',
        method: 'put',
        data: data
    })
}
// 编辑直播
export function editRoomList(data: any) {
    return request({
        url: '/room',
        method: 'put',
        data: data
    })
}